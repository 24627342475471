import React from "react";
import axios from "axios";
import Loading from "../../components/common/loading";

import NotificationManager from "../../utils/notificationManager";

import PDUAccessDropdown from "../../components/users/pduAccessDropdown";
import ToggleInput from "../../components/common/inputs/toggleInput";

import { Link } from "react-router-dom";
const columnWidths = [
  1, // user ID static text
  5, // email static text
  5, // temp user static text toggle?
  2, // enterprise_admin toggle // can only be one
  2, // administrator toggle
  6, // multi select for which PDUs OR PDU groups they can access?
  1, // temporary lock on temp user toggle?
  2, // remove
];

export default class UserTeamMembers extends React.Component {
  render() {
    if (!this.props.resources.userEnterpriseLoaded) {
      return <Loading />;
    }
    return (
      <div className="grid-x grid-padding-x grid-padding-y">
        <ManageTeamMembers {...this.props} />
      </div>
    );
  }
}

class ManageTeamMembers extends React.Component {
  state = {
    // users: [],
    // email_verification_required: false,
    // just_in_time_provisioning_enabled: false,
    // auto_approval_on: false,

    hasChanges: false,
    newUserFormActive: false,
    newUserEmail: "",
    newUserHasAdminPrivileges: false,
    newUserPasswordLoginDisabled: false,
    newUserPDUs: [],
  };

  componentDidMount() {
    // this.props.resources.userEnterpriseLoaded && this.setState({
    //   ...this.props.resources.userEnterprise,
    // });
    if (
      !this.props.resources.groupsLoaded &&
      !this.props.resources.groupsLoading
    ) {
      this.props.fetchResource("groups");
    }
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.resources.userEnterprise !== this.props.resources.userEnterprise) {
  //     this.setState({
  //       ...this.props.resources.userEnterprise,
  //     });
  //   }
  // }

  onSave = () => {
    this.setState({
      loading: true,
    })
    const data = {
      email_id: this.state.newUserEmail,
      has_admin_privileges: this.state.newUserHasAdminPrivileges,
      password_login_disabled: this.state.newUserPasswordLoginDisabled,
      pdu_access_list: this.state.newUserPDUs,
    }
    axios.post(`/v1/enterprises/${this.state.id}/users`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then((res) => {
      NotificationManager.success("User added successfully");
      this.setState({
        loading: false,
        hasChanges: false,
        newUserFormActive: false,
        newUserEmail: "",
        newUserHasAdminPrivileges: false,
        newUserPasswordLoginDisabled: false,
        newUserPDUs: [],
      });
      this.props.updateFetchWrapperState({
        userEnterprise: {
          ...this.props.resources.userEnterprise,
          users: [
            ...this.props.resources.userEnterprise.users,
            res.data.data,
          ]
        }
      })
      // navigate to this user's page
      this.props.history.push(`/team/members/${res.data.data.user_id}`);
    }).catch(err => {
      this.setState({
        loading: false,
      })
      NotificationManager.alert("Error occurred adding user");
    })
  }

  render() {
    // const users = [
    //   ...this.state.users.filter(u => u.is_enterprise_admin),
    //   // {line: true},
    //   ...this.state.users.filter(u => !u.is_enterprise_admin),
    // ]

    const allUsers = this.props.resources.userEnterprise?.users || []
    const users = [
      ...allUsers.filter(u => u.is_enterprise_admin),
      ...allUsers.filter(u => !u.is_enterprise_admin),
    ];

    const foundEnterprise = this.props.resources.userEnterprise;

    return (
      <div className="cell large-12">
        <div
        >
          <h4>Manage Team Members</h4>
        </div>
        {/* <div
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            <div
              style={{
                fontSize: "14px",
                color: "#555",
              }}
            >
              <div>
                <b>Admin Privileges</b>: a user who can add other users to the
                enterprise and assign them select PDUs.
              </div>
              <div>
                <b>Allowed PDUs</b>: When this user logs in, the PDUs they will
                have access to be will be the PDUs in this list. PDU groups may
                be specified as well. They can be created under Devices -> Power
                Consumption
              </div>
              <div>
                <b>Active</b>: If this is inactive, then the user won't be able
                to login.
              </div>
              <div style={{ marginTop: "10px" }}>
                Once a user is added, a verification email will be sent for the
                user to set up their password. Users will be required to verify
                their email before proceeding.
              </div>
            </div>
          </div>
        </div> */}
        <div style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ textWrap: "nowrap", marginLeft: "10px" }}>
              {this.state.newUserFormActive ? (
                <span
                  className="button"
                  style={{
                    backgroundColor: "#eee",
                    color: "#111",
                  }}
                  onClick={() => {
                    this.setState({
                      newUserFormActive: false,
                      newUserEmail: "",
                      newUserHasAdminPrivileges: false,
                      newUserPasswordLoginDisabled: false,
                      newUserPDUs: [],
                      hasChanges: false,
                    });
                  }}
                >
                  Cancel New User
                </span>

              ) : (
                <span
                  className="button"
                  onClick={() => {
                    // this.addTempUser();
                    this.setState({
                      newUserFormActive: true,
                    });
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i className="material-icons">person_add</i>
                  <span>Add User</span>
                </span>

              )}
            </div>
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  opacity: this.state.hasChanges ? 1 : 0,
                }}
              >
                <button
                  className="button"
                  onClick={() => {
                    if (!this.loading) {
                      this.onSave();
                    }
                  }}
                >
                  {this.state.loading ? <Loading /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-row header">
          <div style={{ flex: columnWidths[0] }}>Active</div>
          <div style={{ flex: columnWidths[1] }}>Email</div>
          <div style={{ flex: columnWidths[2] }}>Status</div>
          <div style={{ flex: columnWidths[3] }}>Admin Privileges</div>
          <div style={{ flex: columnWidths[4] }}>Password Login Allowed</div>
          <div style={{ flex: columnWidths[5] }}>Allowed PDUs</div>
          {/* <div style={{ flex: columnWidths[6] }}></div> */}
        </div>
        <div className="grid-row-wrapper">
          {this.state.newUserFormActive && (
            <div className="grid-row">
              <div style={{ flex: columnWidths[0] }}></div>
              <div style={{ flex: columnWidths[1] }}>
                <input
                  type="text"
                  style={{
                    marginBottom: "0px"
                  }}
                  value={this.state.newUserEmail}
                  onChange={(e) => {
                    this.setState({
                      newUserEmail: e.target.value,
                      hasChanges: true,
                    });
                  }}
                  placeholder="example@domain.com"
                />
              </div>
              <div style={{ flex: columnWidths[2] }}>--</div>
              <div style={{ flex: columnWidths[3] }}>
                <ToggleInput
                  inputContainerStyle={{ padding: "0px" }}
                  value={this.state.newUserHasAdminPrivileges}
                  type="checkbox"
                  checked={this.state.newUserHasAdminPrivileges}
                  callback={(value) => {
                    this.setState({
                      newUserHasAdminPrivileges: value,
                      hasChanges: true,
                    });
                  }}
                  id="adminPrivileges"
                />
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <ToggleInput
                  inputContainerStyle={{ padding: "0px" }}
                  value={this.state.newUserPasswordLoginDisabled}
                  type="checkbox"
                  checked={this.state.newUserPasswordLoginDisabled}
                  callback={(value) => {
                    this.setState({
                      newUserPasswordLoginDisabled: value,
                      hasChanges: true,
                    });
                  }}
                  id="passwordLoginDisabled"
                />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                {this.props.devicesLoading ? (
                  <Loading black />
                ) : (
                  <PDUAccessDropdown
                    pdu_access_list={this.state.newUserPDUs}
                    devices={this.props.resources.devices}
                    devicesLoading={this.props.resources.devicesLoading}
                    groups={this.props.resources.groups}
                    onPduAccessListChange={updatedPduList => {
                      this.setState({
                        newUserPDUs: updatedPduList,
                        hasChanges: true,
                      });
                    }}
                  />
                )}
                
              </div>
            </div>
          )}
          {users.map((user, i) => {
            if (user.line) {
              return (
                <div key={i}
                  style={{
                    borderBottom: "1px solid transparent",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >

                </div>
              )
            }
            return (
              <div className="grid-row" key={i}>
                <div style={{ flex: columnWidths[0]}}>
                  {user.active ? (
                    <i className="material-icons" style={{ color: "green" }}>
                      check
                    </i>
                  ) : (
                    <i className="material-icons" style={{ color: "red" }}>
                      close
                    </i>
                  )}
                </div>
                <div style={{ flex: columnWidths[1] }}>
                  <Link to={`/team/members/${user.user_id}`}>
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {user.email_id}
                      </span>
                    </Link>
                </div>
                <div style={{ flex: columnWidths[2] }}>
                  {/* Status: 
                  
                    check for any circumstance that prevents the user from logging in
                    
                    1. check this.state.email_verification_required and if its true, and the user.email_verified false then add into status: "Email verification missing and required"
                    2. check user.approved and if its false then add into status: "User requires approval"
                    3. check user.active and if its false then add into status: "User is inactive"
                    4. check user.active_in_enterprise and if its false then add into status: "User is inactive in enterprise"
                    5. check this.state.SAML_ENABLED is false and user.password_login_disabled is false and user.obscuredPassword is false then add into status: "User needs to setup password" 
                    6. if everything is OK, then say OK
                  */}
                  <ul style={{ margin: "0px", marginLeft: '12px', color: "maroon"}}>
                  {foundEnterprise?.email_verification_required && !user.email_verified && (
                    <li>Email verification missing and required</li>
                  )}
                  {!user.approved && <li>User requires approval</li>}
                  {!user.active && <li>User is inactive</li>}
                  {!user.active_in_enterprise && <li>User is inactive in enterprise</li>}
                  {!foundEnterprise?.SAML_ENABLED && !user.password_login_disabled && !user.obscuredPassword && <li>User needs to setup password</li>}

                  {user.password_login_disabled && !foundEnterprise?.SAML_ENABLED && <li>Password and SSO login are both disabled</li>}
                  {/* {foundEnterprise?.SAML_ENABLED || (!user.password_login_disabled && user.obscuredPassword) && <li>OK</li>} */}
                  </ul>
                </div>
                <div style={{ flex: columnWidths[3] }}>
                  {user.has_admin_privileges ? (
                    <i className="material-icons" style={{ color: "green" }}>
                      check
                    </i>
                  ) : (
                    <i className="material-icons" style={{ color: "#fff" }}>
                      {/* remove */}
                    </i>
                  )}
                </div>
                <div style={{ flex: columnWidths[4] }}>
                  <span>
                    {user.password_login_disabled ? (
                    <i className="material-icons" style={{ color: "#fff" }}>
                        {/* remove */}
                      </i>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <i className="material-icons" style={{ color: "green" }}>
                          check
                        </i>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#666",
                          }}
                        >
                          {!user.obscuredPassword && "No password set"}
                        </span>
                      </span>
                    )}
                  </span>
                  <span>

                  </span>
                </div>
                <div style={{ flex: columnWidths[5] }}>
                  {/* <PDUAccessDropdown
                    user={user}
                    editable={false}
                    onChange={(pdus) => {
                      // this.onPDUChange(user, pdus);
                    }}
                  /> */}
                  {user.is_enterprise_admin ? (
                    <div>All PDUs allowed</div>
                  ) : this.props.devicesLoading ? (
                    <Loading black />
                  ) : (
                    <PDUAccessDropdown
                      pdu_access_list={user.pdu_access_list}
                      editable={false}
                      onChange={(pdus) => {
                        // this.onPDUChange(user, pdus);
                      }}
                    />
                  )}
                </div>
                {/* <div style={{ flex: columnWidths[6] }}>
                  <span
                    style={{
                      backgroundColor: "#11181f",
                      color: "#fff",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      // padding: "5px 20px",
                      borderRadius: "2px",
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",

                    }}
                  >
                    edit
                  </span>
                </div> */}
              </div>
            )
          })}
        </div>
        
      </div>
    );
  }
}
