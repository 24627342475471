import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import ToggleInput from "../../components/common/inputs/toggleInput";
import Loading from "../../components/common/loading";
import axios from "axios";
import NotificationManager from "../../utils/notificationManager";

export default class TeamSettings extends React.Component {
  state = {
    // requireEmailVerification: false,
    name: "",
    just_in_time_provisioning_enabled: false,
    email_verification_required: false,
    auto_approval_on: false,

    hasChanges: false,

    loading: false,
    users: [],
  };
  componentDidMount() {
    this.props.resources.userEnterpriseLoaded && this.setState({
      ...this.props.resources.userEnterprise,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.resources.userEnterprise !== this.props.resources.userEnterprise) {
      this.setState({
        ...this.props.resources.userEnterprise,
      });
    }
  }
  onCancel = () => {
    this.setState({
      hasChanges: false,
      fullName: this.props.user.full_name,
      company: this.props.user.company,
    });
  };

  onSave = () => {
    this.setState({
      // hasChanges: false,
      loading: true,
    });
    const data = {
      name: this.state.name,
      just_in_time_provisioning_enabled: this.state.just_in_time_provisioning_enabled,
      email_verification_required: this.state.email_verification_required,
      auto_approval_on: this.state.auto_approval_on,
    };

    axios.put("/v1/enterprises/"+this.state.id,data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then((res) => {
      this.setState({
        loading: false,
        hasChanges: false,
      });
      this.props.updateFetchWrapperState({
        userEnterprise: res.data.data
      })
      NotificationManager.success("Team settings updated successfully");
    }) .catch((err) => {
      console.log(err);
      this.setState({
        loading: false,
      });
      NotificationManager.alert("Failed to update team settings");
    } );
    
  };
  render() {
    // const { enterprise } = this.props.user;
    // if (!enterprise) {
    // }

    // const enterpriseAdmins = ((this.props.user.enterprise || {}).enterpriseUsers || []).filter(user => user.is_enterprise_admin).map(user => user.email_id)
    
    const enterpriseAdmins = this.state.users.filter(user => user.is_enterprise_admin).map(user => user.email_id)

    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
          <div
            className="cell"
            style={{ paddingBottom: "0px", paddingTop: "10px" }}
          >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              opacity: this.state.hasChanges ? 1 : 0,
            }}
          >
            <button
              className="button"
              style={{
                backgroundColor: "#eee",
                color: "#111",
                marginRight: "10px",
              }}
              onClick={() => {
                this.onCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                if (!this.loading) {
                  this.onSave();
                }
              }}
            >
              {this.state.loading ? <Loading /> : "Save"}
            </button>
          </div>
            <h4 >Team Settings</h4>
          </div>
          <div className="cell large-8">
            <div>
              <TextInput
                label="Team Name"
                value={this.state.name}
                callback={(value) => {
                  this.setState({
                    name: value,
                    hasChanges: true,
                  });
                }}
              />
              {/* require email verification */}
              <ToggleInput
                label="Require Email Verification"
                description="Users must verify their email address before they can log in."
                value={this.state.email_verification_required}
                type="checkbox"
                checked={this.state.email_verification_required}
                callback={(status) => {
                  this.setState({
                    email_verification_required: status,
                    hasChanges: true,
                  });
                }}
                id="email-verification-tl1"
              />
              <ToggleInput
                label="Auto Approve Users"
                description="Automatically approve user accounts when they sign up or login through SSO. Accounts that are not approved will require manual approval from an administrator. Users created by an administrator are always approved regardless of Auto Approve setting."
                value={this.state.auto_approval_on}
                type="checkbox"
                checked={this.state.auto_approval_on}
                callback={(status) => {
                  this.setState({
                    auto_approval_on: status,
                    hasChanges: true,
                  });
                }}
                id="autoapproval-tl1"
              />
              <ToggleInput
                label="Just in Time Provisioning"
                description="User accounts are created the first time a user logs in via Single Sign-On (SAML2.0) service if they do not already exist. Users provisioned immediately have access to all devices. If auto approval is off, the user will require manual approval from an administrator. This may be useful for organizations that want to assign a device group to the new user before they have access."
                // just in time provisioning
                value={this.state.just_in_time_provisioning_enabled}
                type="checkbox"
                checked={this.state.just_in_time_provisioning_enabled}
                callback={(status) => {
                  this.setState({
                    just_in_time_provisioning_enabled: status,
                    hasChanges: true,
                  });
                }}
              />
              {enterpriseAdmins.map(user => (
                <TextInput
                  label="Team Administrator"
                  value={user}
                  readOnly
                />

              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
