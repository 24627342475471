import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import PageTemplate from '../components/common/pageTemplate';

import pages from './routes';

export default class ActivePageContainer extends React.Component {

  render() {
    // const { device, user, conf } = this.props;
    // if (!device.modelNumber) return null;
    const { user } = this.props;
    const routes = pages(user);

    return (
      <Switch>
        {routes.map(page => {
           return (
            <Route
              key={page.basePath}
              path={page.basePath}
              exact={page.exact || undefined}
              render={(routerProps) => {
                if (page.disabled) {
                  return null;
                }
                return (
                  <PageTemplate
                    {...page}
                    {...this.props}
                    {...routerProps}
                  />
                )
              }}
            />
        )})}
        <Redirect exact from ="/" to="/devices" />
      </Switch>
    );
  }
}
