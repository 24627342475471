import React from 'react';
import axios from "axios";
import { Switch, Route, Redirect } from 'react-router-dom'

import Loading from '../common/loading';
import parseQueryParam from '../../utils/parseQueryParams';
import AppLogout from './autoLogout';

import Login from './login';
import Signup from './signup';
import EmailVerification from './emailVerification';
import PendingApproval from './pendingApproval';
import ResetPassword from './resetPassword'
import EnterpriseSetup from './enterpriseSetup';
import ConfirmEmail from './confirmEmail';
import DeploymentSettings from './deploymentSettings'

import NotificationManager from '../../utils/notificationManager';
import ModalManager from '../common/modal/ModalManager'

// public pages
import Pricing from './pricing';
import BetaTerms from './betaTerms';

const logo = (
  <svg className="App-logo" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M14.28,14.08L12.26,16.1C11.66,16.7 10.87,17 10.08,17C9.29,17 8.5,16.7 7.9,16.1C6.7,14.9 6.7,12.95 7.9,11.74L9.15,10.5L9.14,11.06C9.14,11.5 9.21,11.95 9.36,12.37L9.41,12.5L9.04,12.87C8.76,13.15 8.6,13.53 8.6,13.92C8.6,14.32 8.76,14.69 9.04,14.97C9.6,15.53 10.57,15.53 11.13,14.97L13.14,12.96C13.43,12.67 13.58,12.3 13.58,11.91C13.58,11.5 13.43,11.14 13.15,10.86C13,10.71 12.9,10.5 12.9,10.29C12.9,10.08 13,9.88 13.15,9.73C13.45,9.42 14,9.43 14.28,9.73C14.86,10.31 15.18,11.08 15.18,11.9C15.18,12.73 14.86,13.5 14.28,14.08M17.1,11.26L15.85,12.5L15.86,11.94C15.86,11.5 15.79,11.06 15.64,10.64L15.6,10.5L15.96,10.13C16.25,9.85 16.4,9.5 16.4,9.08C16.4,8.69 16.25,8.32 15.97,8.04C15.4,7.47 14.43,7.47 13.87,8.04L11.86,10.05C11.58,10.33 11.42,10.7 11.42,11.1C11.42,11.5 11.57,11.86 11.86,12.14C12,12.29 12.1,12.5 12.1,12.71C12.1,12.93 12,13.13 11.85,13.28C11.7,13.44 11.5,13.5 11.29,13.5C11.09,13.5 10.88,13.43 10.72,13.28C9.5,12.08 9.5,10.12 10.72,8.92L12.74,6.9C13.95,5.7 15.9,5.7 17.1,6.9C17.68,7.5 18,8.26 18,9.08C18,9.9 17.68,10.68 17.1,11.26Z" />
  </svg>
);

const DEFAULT_LOGIN_TIME = 2100;

function authWrapper(WrappedComponent) {
  return class extends React.Component {
    state = {
      authorized: false,
      userLoading: false,
      user: null,
      errMessage: "",
      signupErrMessage: "",
    }
    componentDidMount() {
      this.processLocalStorageOrQueryParameters();
    }

    /**
     * You can prelogin with 2 separate ways
     * 1. Query Parameters
     *  - t: token
     *  - u: userId
     *  - These are available only through SAML SSO Redirect from iDP initiated login flows. They use the default login time.
     * 2. Local Storage
     *  - token
     *  - userId
     *  - tokenExpiration
     * 
     * when sucessfully authenticating either, a token is stored, a token expiration time, and if logged in with query parameter/saml then a  redirect login url is made
     * 
     * 
     */
    processLocalStorageOrQueryParameters = () => {
      const { history } = this.props;
      const cookie = localStorage.getItem("token");
      const token = parseQueryParam(history.location.search).t;
      const userId = parseQueryParam(history.location.search).u;
      if (([
        "/register",
        "/reset",
        "/confirm-email",
        "/pricing",
        "/enterprise-setup",
        "/deployment-settings"
      ].includes(history.location.pathname))) {
        // ignore register for auth
        if ((history.location.pathname === "/confirm-email") && localStorage.getItem("userId")) {
          this.fetchUser()
        }
      } else {
        if (token) { // assume if there is a token in the query parameters that expiration time is 30s
          // console.log("PROCESSING QUERY PARAMETER TOKEN FROM SAML")
          localStorage.setItem("token", token)
          localStorage.setItem("loginMethod", "saml")
          localStorage.setItem("tokenExpiration", new Date().getTime() + DEFAULT_LOGIN_TIME * 1000);
          localStorage.setItem('userId', userId)
          this.fetchUser((err, pkg) => {
            if (err) { // USER IS TRYING TO LOGIN WITH A FAULTY SAML TOKEN IN THE QUERY PARAMETER
              // console.log("SAML token is not valid")
              this.props.history.replace(`/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
            } else { // USER IS LOGGING IN WITH A SAML TOKEN IN THE QUERY PARAMETER
              const user = pkg.data;
              // console.log("SAML token is valid.")
              localStorage.setItem("samlLoginUrl", user.SAML_LOGIN_URL)
              this.loginTimeoutModal(DEFAULT_LOGIN_TIME * 1000) // logged in successfully with saml
            }
          })
        } else if (cookie) {


          this.fetchUser((err, user) => {
            if (err) { // USER IS TRYING TO LOGIN WITH A FAULTY LOCAL STORAGE COOKIE
              if (err.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("tokenExpiration");
                localStorage.removeItem("userId");

                this.setState({
                  authorized: false,
                  // user: null
                })
                // console.log("CLEARING LOCAL STORAGE")
              }
              const samlLoginurl = localStorage.getItem("samlLoginUrl");
              if (samlLoginurl) { // USER IS TRYING TO LOGIN WITH A FAULTY LOCAL STORAGE COOKIE FROM SAML
                // window.location = this.state.user.enterprise.SAML_LOGIN_URL
                window.location = samlLoginurl;
              } else { // USER IS TRYING TO LOGIN WITH A FAULTY LOCAL STORAGE COOKIE FROM PASSWORD LOGIN
                // console.log("REDIRECTINBG TO ", `/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
                this.props.history.replace(`/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
              }
            } else { // USER IS LOGGING IN WITH A GOOD LOCAL STORAGE COOKIE
              const tokenExpiration = localStorage.getItem("tokenExpiration");
              if (tokenExpiration) {
                const timeUntilExpiration = parseInt(tokenExpiration, 10) - new Date().getTime();
                // console.log("TIME UNTIL EXPIRATION", timeUntilExpiration)
                if (timeUntilExpiration > 0) {
                  this.loginTimeoutModal(timeUntilExpiration);
                } else {
                  // console.log("Expired token expiration time, no timeout modal created for later")
                }
              } else {
                // console.log("NO TOKEN EXPIRATION TIME FOUND")
              }
              // this.loginTimeoutModal()
            }
          });

        } else {
          // console.log("NO LOGIN INFORMATION FOUND")
          // console.log("redirecting because no cookie")


          const { pathname } = history.location
          const redirect = (pathname === "/login" || pathname === "/") ? "" : `?redirect=${pathname}`;
          this.props.history.replace(`/login${redirect}`);

        }
      }
    }

    loginTimeoutModal = (timeoutInMs = DEFAULT_LOGIN_TIME * 1000) => {
      this.timer = setTimeout(() => {
        ModalManager.confirm(
          "You have been signed out. Please sign in to access Synaccess Cloud.",
          "Session Expired",
          "Sign In",
          (status, close) => {
            if (status) { // clicked sign in
              const loginMethod = localStorage.getItem("loginMethod");

              if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.SAML_LOGIN_URL && loginMethod === "saml") {
                window.location = this.state.user.enterprise.SAML_LOGIN_URL
              } else {
                console.log("Timeout, and Modal selected. Reprocessing credentials to redirect appropriately")
                this.processLocalStorageOrQueryParameters()
                // this.props.history.replace(`/login?redirect=${this.props.history.location.pathname === "/logout" ? "/" : this.props.history.location.pathname}`)
              }
              close();

            } else { // clicked cancel or outside

            }
          },
          null,
          true
        );
      }, timeoutInMs)
    }
    updateUser = (obj) => {
      this.setState(obj);
    }

    updateUserFetch = (user, body, cb = () => { }) => {
      axios.put(`/v1/users/${user.id}`, body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        cb(null, res.data);
        console.log(res.data.data)
        this.setState({// replace single item in array for resource being edited, search by ID match
          user: res.data.data
        })
      }).catch(err => {
        cb(err, null);
        console.log(err)
      })
    }

    fetchUser = (cb = () => { }) => {
      this.setState({ userLoading: true })
      axios
        .get(`/v1/users/${localStorage.getItem("userId")}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        })
        .then(res => {
          this.setState({ userLoading: false });
          this.setState({
            // users: res.data.data
            authorized: true,
            // ...res.data
            user: res.data.data
          })
          cb(null, res.data)
        })
        .catch(err => {
          this.setState({ userLoading: false });
          cb(err);
          console.log(err)
        })
    }
    fetchLogin = (username, password, cb = () => { }, stayLoggedIn) => {
      this.setState({
        userLoading: true,
        errMessage: ""
      })
      axios
        .post('/v1/auth/login', {
          email: username,
          password,
          stayLoggedIn
        })
        .then(res => {
          cb()
          this.setState({
            userLoading: false
          })
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("tokenExpiration", new Date().getTime() + res.data.tokenExpirationTime * 1000); // set token expiration by taking the current time and adding the expiration time
            localStorage.setItem("userId", res.data.user.user_id);

            // call loginTimeoutModal and calculate the time until the token expires and set that as the parameter
            this.loginTimeoutModal(res.data.tokenExpirationTime * 1000);

            this.setState({
              authorized: true,
              user: res.data.user
            });
            const redirectUrl = parseQueryParam(this.props.history.location.search).redirect;
            this.props.history.replace(redirectUrl || "/");

          } else {
            NotificationManager.alert("Incorrect email or password.");
            this.setState({
              errMessage: "Incorrect email or password."
            })
          }
        }).catch(error => {
          console.log(error)
          cb()
          NotificationManager.alert("Username and/or Password Invalid.");
          this.setState({
            userLoading: false
          })
          if (error.response.status === 401) {
            this.setState({
              errMessage: "Username and/or Password Invalid."
            })
          } else if (error.response.status === 400) {
            this.setState({
              errMessage: error.response.data.message
            })
          } else {
            this.setState({
              errMessage: "Error Occurred"
            })

          }
        })
    }
    fetchSignup = ({
      fullName,
      password,
      email,
      company
    },
      cb = () => { }
    ) => {
      this.setState({
        signupErrMessage: ""
      })
      axios
        .post('/v1/auth/register', {
          fullName: fullName,
          password,
          email,
          company
        })
        .then(res => {
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("userId", res.data.user.user_id)
          this.setState({
            authorized: true,
            user: res.data.user
          })
          this.props.history.replace("/email-verification")
        })
        .catch(err => {
          console.log(err)
          cb(err)
          if (err.response) {
            this.setState({
              signupErrMessage: err.response.data.message
            })
          }
        })
    }
    signout = () => {

      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("userId");
      this.props.history.replace("/login");
      this.setState({
        authorized: false,
        user: null,
      })
    }

    resendVerificationEmail = (cb = () => { }) => {
      axios
        .post("/v1/auth/resend-verification", {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then(res => {
          console.log(res)
          cb(null, res);
          NotificationManager.success("Verification email sent!");
        }).catch(err => {
          console.log(err)
          cb(err, null)
        })

    }
    submitForgottenPassEmail = (email, cb = () => { }) => {
      axios
        .post("/v1/auth/reset", {
          email
        }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then(res => {
          console.log(res)
          cb(null, res);
        }).catch(err => {

          console.log(err)
          cb(err, null)
        })
    }

    confirmEmail = ({
      token,
      cookie,
      password
    }, cb = () => { }) => {
      console.log("confirming")
      axios.post("/v1/auth/confirm-email", { token, cookie, password }, {})
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("userId", res.data.data.user_id)
            this.setState({
              authorized: true,
              user: res.data.data
            }, () => {
              cb(null, res);
              this.props.history.replace("/")
            })
          } else {
            cb({ err: "nah" }, null)
          }
        }).catch(err => {
          cb(err, null);
        })
    }
    resetPassword = ({ password, currentPass }, cb = () => { }) => {
      // const token = localStorage.getItem("token") || parseQueryParam(this.props.history.location.search).t
      const token = localStorage.getItem("token");
      const email_token = parseQueryParam(this.props.history.location.search).t
      axios.post("/v1/auth/reset/token", {
        token,
        email_token,
        password,
        currentPass
      }, {})
        .then(res => {
          if (res.status === 200) {
            cb(null, res.data);
            this.setState({
              user: res.data.data,
              authorized: true
            })
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("userId", res.data.data.user_id)

          } else {
            cb(res.data, null);
            console.log("bad response from reset token request")
          }
        })
        .catch(err => {
          cb(err.response.data, null);
          console.log(err)
        })
    }
    setupEnterpriseUser = ({ password, currentPass }, cb = () => { }) => {
      // const token = localStorage.getItem("token") || parseQueryParam(this.props.history.location.search).t
      const token = localStorage.getItem("token");
      const email_token = parseQueryParam(this.props.history.location.search).t
      axios.post("/v1/auth/reset/enterprise-token", {
        token,
        email_token,
        password,
        currentPass
      }, {})
        .then(res => {
          if (res.status === 200) {
            cb(null, res.data);
            this.setState({
              user: res.data.data,
              authorized: true
            })
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("userId", res.data.data.user_id)

          } else {
            cb(res.data, null);
            // console.log("bad response from reset token request")
          }
        })
        .catch(err => {
          cb(err.response.data, null);
          console.log(err)
        })
    }
    render() {
      const { history } = this.props;
      const userId = localStorage.getItem("userId");

      return (
        <Switch>
          <Route
            path="/register"
            render={() => {
              return (
                <Signup
                  fetchSignup={this.fetchSignup}
                  signupErrMessage={this.state.signupErrMessage}
                />
              )
            }}
          />
          <Route
            path="/login"
            render={() => {
              // return null
              const { authorized } = this.state;
              if (authorized) {
                const redirectUrl = parseQueryParam(history.location.search).redirect;
                return (
                  <Redirect to={redirectUrl || "/"} />
                )
              } else {
                return (
                  <Login
                    fetchLogin={this.fetchLogin}
                    errMessage={this.state.errMessage}
                  />
                )
              }
            }}
          />
          <Route
            path="/email-verification"
            render={() => {
              const { authorized } = this.state;
              const user = this.state.user;
              if (user && user.email_verified && authorized) {
                return <Redirect to={"/"} />
              }
              if (!userId) {
                return (
                  <Redirect to={"/login"} />
                )
              }
              if (this.state.user && this.state.user.emailVerified) {
                return <Redirect to="/" />
              }
              return (
                <EmailVerification
                  userId={userId}
                  history={this.props.history}
                  signout={this.signout}
                  resendVerificationEmail={this.resendVerificationEmail}
                />
              )
            }}
          />
          <Route
            path="/pending-approval"
            render={() => {
              const { authorized } = this.state;
              const user = this.state.user;
              if (user && user.approved && authorized) {
                return <Redirect to={"/"} />
              }
              return (
                <PendingApproval
                  userId={userId}
                  signout={this.signout}
                />
              )
            }}
          />
          <Route
            path="/confirm-email"
            render={() => {
              if (this.state.userLoading) return <Loading fixed />

              return (
                <ConfirmEmail
                  userLoading={this.state.userLoading}
                  authorized={this.state.authorized}
                  user={this.state.user}
                  confirmEmail={this.confirmEmail}
                  history={this.props.history}
                />
              )
            }}
          />
          <Route
            path="/reset"
            render={() => {
              // const { authorized } = this.state;
              // const user = this.state.user;
              // if (user && user.approved && authorized) {
              //   return <Redirect to={"/"} />
              // }
              return (
                <ResetPassword
                  submitForgottenPassEmail={this.submitForgottenPassEmail}
                  history={this.props.history}
                  resetPassword={this.resetPassword}
                  updateUser={this.updateUser}
                />
              )
            }}
          />
          <Route
            path="/enterprise-setup"
            render={() => {
              return (
                <EnterpriseSetup
                  submitForgottenPassEmail={this.submitForgottenPassEmail}
                  history={this.props.history}
                  setupEnterpriseUser={this.setupEnterpriseUser}
                  updateUser={this.updateUser}
                />
              )
            }}
          />
          <Route
            path="/pricing"
            render={() => {
              return (
                <Pricing
                />
              )
            }}
          />
          <Route
            path="/deployment-settings"
            render={(props) => {
              return (
                <div>
                  <div>

                    <header
                      style={{
                        backgroundColor: '#223647',
                        padding: '20px',
                        // paddingTop: '40px',
                        // marginTop: '10px',
                        color: 'white',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: '12px',

                        //   /* background-color: #222; */
                        //   /* height: 100px; */
                        //   padding: 10px;
                        //   padding-top: 0px;
                        //   margin-top: 10px;
                        //   color: white;
                        //   text-align: center;
                        //   display: flex;
                        //   justify-content: flex-start;
                        //   align-items: center;
                        //   /* flex-direction: column; */
                        //   margin-bottom: 12px;
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fill: "white",
                        }}
                      >
                        {logo}
                      </div>
                    <div className="App-title">Synaccess Cloud</div>
                    </header>
                  </div>
                  <div className="grid-container">

                    <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">

                      <div className="cell large-12" style={{ paddingBottom: "0px" }}>
                        <h3>Deployment Settings</h3>
                        <div>Welcome to Synaccess Cloud. Set up your email configurations for notifications and management. To get started, create an Administrator who will manage deployment settings and user/group management  </div>
                      
                      </div>
                    </div>
                  </div>
                  <DeploymentSettings {...props} />
                </div>
              )
            }}
          />
          <Route
            path="/beta-terms"
            render={() => {
              return (
                <BetaTerms
                />
              )
            }}
          />
          <Route
            render={() => {
              const { authorized } = this.state;
              if (this.state.userLoading) {
                return (
                  <Loading
                    size="40px"
                    black
                    fixed
                  />
                );

              } else if (!authorized) {
                return null;

              } else if (authorized) {
                if (!this.state.user) return null;
                if (!this.state.user.email_verified) {
                  return <Redirect to={"/email-verification"} />
                }
                if (!this.state.user.approved) {
                  return <Redirect to={"/pending-approval"} />
                }
                return (
                  <AppLogout
                    updateUser={this.updateUser}
                    history={history}
                  >
                    <WrappedComponent
                      user={{
                        ...this.state.user,
                      }}
                      updateUser={this.updateUser}
                      updateUserFetch={this.updateUserFetch}
                      resetPassword={this.resetPassword}

                      {...this.props}
                    />
                  </AppLogout>
                );
              } else {
                return (
                  <Redirect to={`/login?redirect=${history.location.pathname === "/logout" ? "/" : history.location.pathname}`} />
                );
              }
            }}
          />
        </Switch>
      );
    }
  }
}

export default authWrapper