import React from "react";
import Loading from "../common/loading";
export default class PDUAccessDropdown extends React.Component {
  render() {
    const {
      // user,
      pdu_access_list,
      onPduAccessListChange = () => {},
      devices = [],
      devicesLoading = false,
      groups = [],
      editable = true,
    } = this.props;

    const deviceGroups = groups.filter((group) => group.groupType == "devices");

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          width: "100%",
        }}
      >
        <span
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "3px 5px",
            // fontSize: "12px"
          }}
        >
          {(pdu_access_list || []).length === 0 && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                fontWeight: "500",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                marginRight: "3px",
                backgroundColor: "#223647",
                color: "white",
                fontSize: "13px",
                paddingTop: "2px",
                paddingBottom: "2px",
                marginBottom: "3px",
                paddingLeft: "2px",
                paddingRight: "4px",
              }}
            >
              All PDUs
            </span>
          )}
          {pdu_access_list &&
            pdu_access_list.map((pdu) => {
              const label = (pdu || "").startsWith("Group:")
                ? `Group:${
                    (
                      deviceGroups.find(
                        (group) => group.id == pdu.split(":")[1]
                      ) || {}
                    ).name || `${pdu}`
                  }`
                : pdu;
              return (
                <span
                  key={pdu}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontWeight: "500",
                    borderRadius: "5px",
                    textAlign: "center",
                    cursor: editable ? "pointer" : "",
                    marginRight: "3px",
                    backgroundColor: "#223647",
                    color: "white",
                    fontSize: "13px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    marginBottom: "3px",
                    paddingLeft: "2px",
                  }}
                  onClick={() => {
                    // this.setState({
                    //   enterpriseUsers: this.state.enterpriseUsers.map((u) => {
                    //     if (u.user_id == user.user_id) {
                    //       return {
                    //         ...u,
                    //         pdu_access_list: u.pdu_access_list.filter(
                    //           (p) => p != pdu
                    //         ),
                    //       };
                    //     } else {
                    //       return u;
                    //     }
                    //   }),
                    //   hasChanges: true,
                    // });
                    // onPduAccessListChange(pdu);
                    editable &&
                      onPduAccessListChange(
                        pdu_access_list.filter((p) => p != pdu)
                      );
                  }}
                >
                  <span style={{ marginRight: "3px" }}>{label}</span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {editable && (
                      <i
                        className="material-icons"
                        style={{
                          fontSize: "14px",
                          color: "white",
                          marginRight: "0px",
                        }}
                      >
                        close
                      </i>
                    )}
                  </span>
                </span>
              );
            })}
        </span>
        {devicesLoading ? (
          <Loading black />
        ) : editable ? (
          <select
            value=""
            style={{
              marginBottom: "0px",
            }}
            onChange={(e) => {
              // add enclosure serial number to user's pdu_access_list
              if (e.target.value) {
                if (e.target.value == "ALL PDUs") {
                  onPduAccessListChange(["All PDUs"]);
                } else {
                  if (e.target.value.startsWith("Group:")) {
                    // const parsedGroupId = e.target.value.split(":")[1];
                    onPduAccessListChange(
                      [...(pdu_access_list || []), e.target.value].filter(
                        (p) => p != "All PDUs"
                      )
                    );
                  } else if (e.target.value.startsWith("PDU:")) {
                    onPduAccessListChange(
                      [...(pdu_access_list || []), e.target.value].filter(
                        (p) => p != "All PDUs"
                      )
                    );
                  } else {
                    // do nothing
                    console.log("select option is not recognized");
                  }
                }
              } else {
                console.log("selected the --- option");
                // do nothing
              }
            }}
          >
            <option value="">--</option>
            <option value="ALL PDUs">All PDUs Allowed</option>
            <optgroup label="PDU Groups">
              {deviceGroups
                .filter(
                  (group) =>
                    !(pdu_access_list || []).find(
                      (p) => p == `Group:${group.id}`
                    )
                )
                .map((group) => (
                  <option key={group.id} value={`Group:${group.id}`}>
                    {group.name}
                  </option>
                ))}
            </optgroup>
            <optgroup label="Individual PDUs">
              {devices
                .filter(
                  (pdu) =>
                    !(pdu_access_list || []).find(
                      (p) => p == `PDU:${pdu.enclosureSerialNumber}`
                    )
                )
                .map((pdu) => (
                  <option
                    key={pdu.enclosureSerialNumber}
                    value={`PDU:${pdu.enclosureSerialNumber}`}
                  >
                    SP-{pdu.modelNumber} ({pdu.enclosureSerialNumber}) {pdu.connectionState === "Disconnected" ? "Disconnected" : pdu.deviceName}
                  </option>
                ))}
            </optgroup>
          </select>
        ) : null}
      </div>
    );
  }
}
