import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import Loading from "../../components/common/loading";
import ModalManager from "../../components/common/modal/ModalManager";
import axios from "axios";

// import NotificationManager from '../../components/common/modal/NotificationManager'
import NotificationManager from "../../utils/notificationManager";
console.log(process.env);

export default class LoggedInUser extends React.Component {
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
          <div className="cell"></div>
          <div className="cell large-6 " style={{ paddingTop: "0px" }}>
            <div>
              <h4>Documentation</h4>
            </div>
            <div style={{ padding: "" }}>
              <p>
                Visit the documentation for the HTTP API with JSON scheme at{" "}
                {window.location.origin}/docs. Make sample HTTP requests with
                the API tools. 

                    <a
                      href="/docs/json"
                      style={{
                        // color: "white",
                        // display: "flex",
                        // alignItems: "center",
                        textDecoration: "underline",
                        paddingLeft: "2px"
                      }}
                      target="_blank"
                    >
                      OpenAPI JSON Specification
                      <i
                        style={{ marginRight: "0px", paddingBottom: "-3px" }}
                        className="material-icons"
                      >
                        open_in_new
                      </i>
                    </a>
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: 'center',
                  // justifyContent: 'center',
                }}
              >
                <div>
                  <div className="button" style={{ marginTop: "20px" }}>
                    <a
                      href="/docs"
                      target="_blank"
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>API Documentation</span>
                      <i
                        style={{ marginRight: "0px" }}
                        className="material-icons"
                      >
                        open_in_new
                      </i>
                    </a>
                  </div>
                </div>
                <div>
                  <div className="" style={{ marginTop: "20px" }}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
